import React from 'react'
import { Box, Typography, Button } from '@mui/material'
import { useNavigate, useLocation } from "react-router-dom"
import Immagine from './Immagine'
import { pulseAnimation } from '../utils/animations'

function CardPrincipale(props) {

    const { immagine, titolo, pulsante, path, sx } = props

    const navigate = useNavigate()
    const location = useLocation()

    return (
        <Box sx={{
            ...sx,
            borderRadius: "15px",
            boxShadow: "3px 3px 6px 3px #b6b6b6",
            backgroundColor: "#ffffffe0",
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            alignItems: "center",
            justifyItems: "center",
            paddingY: "0.5rem"
        }}>
            <Immagine immagine={immagine}
                sx={{
                    width: 150,
                    height: 150,
                    "&:hover": {
                        animation: `${pulseAnimation} 0.8s infinite`,
                        animationTimingFunction: "linear"
                    },
                }}
            />
            <Box>
                <Typography>
                    <Box sx={{ marginBottom: "1rem", fontSize: "1.5rem", fontWeight: "bold" }}>
                        {titolo}
                    </Box>
                </Typography>
                <Button
                    sx={{
                        backgroundColor: "secondary.main",
                        textTransform: "none",
                        boxShadow: "3px 3px 6px 3px #b6b6b6",
                        "&:hover": {
                            backgroundColor: "secondary.dark",
                        }
                    }}
                    onClick={() => navigate(location.pathname + path)}
                >
                    {pulsante}
                </Button>
            </Box>
        </Box>
    )
}

export default CardPrincipale