import React from 'react'
import inCostruzione from "../res/immagini/in-costruzione.webp"
import { Box, Typography, Button } from "@mui/material"
import { useNavigate } from "react-router-dom"
import Immagine from "../components/Immagine"

function InCostruzione() {

    const navigate = useNavigate()

    const tornaAllaHome = () => {
        navigate("/")
    }

    return (
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
            <Immagine immagine={inCostruzione} sx={{ marginBottom: "2rem" }} />
            <Typography>Metti il caschetto.</Typography>
            <Typography>La pagina è ancora in costruzione</Typography>
            <Button
                sx={{
                    marginTop: "2rem",
                    backgroundColor: "secondary.main",
                    textTransform: "none",
                    boxShadow: "3px 3px 6px 3px #b6b6b6",
                    "&:hover": {
                        backgroundColor: "secondary.dark",
                    }
                }}
                onClick={() => tornaAllaHome()}
            >
                Torna alla home
            </Button>
        </Box>
    )
}

export default InCostruzione