import React from 'react'
import { Box } from "@mui/material"

function Immagine(props) {

    const { immagine, sx } = props

    return (
        <Box
            component="img"
            src={immagine}
            sx={{
                ...sx,
            }}
        />
    )
}

export default Immagine