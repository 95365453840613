import React from 'react'
import { Box } from "@mui/material"
import { BrowserRouter as Router } from 'react-router-dom'
import Header from './components/Header'
import Footer from './components/Footer'
import sfondo from "./res/immagini/sfondo.webp"
import getRoutes from './utils/routes'
import ScrollToTop from "./utils/ScrollToTop"

function App() {
    return (
        <Router>
            <ScrollToTop />
            <Box
                sx={{
                    backgroundImage: `url(${sfondo})`,
                }}>
                <Header />
                <Box
                    id='body'
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        flex: 1,
                        minHeight: "100vh",
                    }}
                >
                    {getRoutes()}
                </Box>
                <Footer />
            </Box>
        </Router>
    )
}

export default App
