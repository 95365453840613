import React from 'react'
import { Box, Typography } from "@mui/material"

function Testo(props) {

    const { testo, sx } = props

    return (
        <Typography>
            <Box
                sx={{
                    ...sx
                }}
            >
                {testo}
            </Box>
        </Typography>
    )
}

export default Testo