import React from 'react'
import { Box, Typography } from "@mui/material"

function TestoEIcona(props) {

    const { testo, sx, icona, posizioneIcona, sxIcona } = props

    const Icona = React.createElement(icona, sxIcona)

    return (
        <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box sx={{ ...sx, display: "flex", alignItems: "center", marginRight: "20px", ...sxIcona }}>
                {posizioneIcona !== "dx" ? Icona : null}
            </Box>
            <Typography>
                <Box
                    sx={{ ...sx, display: "flex", alignItems: "center" }}
                >
                    {testo}
                </Box>
            </Typography>
            <Box sx={{ ...sx, display: "flex", alignItems: "center", marginLeft: "20px", ...sxIcona }}>
                {posizioneIcona === "dx" ? Icona : null}
            </Box>
        </Box>
    )
}

export default TestoEIcona