import React from 'react'
import { Box } from "@mui/material"
import Label from '../components/Label'
import CardPrincipale from '../components/CardPrincipale'
import SwipeableView from '../components/SwipeableView'
import logo from "../res/immagini/logo.webp"
import { elencoMenu } from '../utils/elencoMenu'

function Home() {

    const renderCards = () => {
        var cards = []
        elencoMenu.forEach(menu => {
            if (menu.nome !== "Home" && menu.nome !== "News")
                cards.push(
                    <CardPrincipale immagine={menu.immagine} titolo={menu.nome} pulsante={"Scopri le offerte"} path={menu.path} />
                )
        })
        return cards
    }

    return (
        <Box id="homePageRoot" sx={{ width: "80vw" }}>
            <Label
                sx={{ marginTop: 8, fontSize: "2.4rem", width: "38rem", height: "5rem" }}
                testo="Non restare fuori dal gregge!"
            />
            <Box sx={{
                display: "grid",
                marginTop: "-6rem",
                marginRight: "-8rem",
                marginLeft: "3rem",
                paddingTop: "9rem",
                paddingRight: "11rem",
                backgroundImage: `url(${logo})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "top right",
                backgroundSize: "22rem",
                gridTemplateColumns: "1fr 1fr 1fr",
                columnGap: "20px",
                rowGap: "2rem"
            }}>
                {renderCards()}
            </Box>

            <Label
                sx={{ marginTop: 8, fontSize: "2.4rem", width: "22rem", height: "5rem" }}
                testo="Lo sapevi che..."
            />
            <Box sx={{ display: "flex", flex: 1, justifyContent: "center", marginBottom: "3rem" }}>
                <SwipeableView sx={{ marginTop: "3rem" }} />
            </Box>

        </Box>
    )
}

export default Home