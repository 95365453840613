import { Route, Routes as Switch } from 'react-router-dom'
import Errore404 from '../pagine/Errore404'
import Home from '../pagine/Home'
import InCostruzione from '../pagine/InCostruzione'

const routes = [
    <>
        <Route exact path="" element={<Home />} />
        <Route exact path="/news" element={<InCostruzione />} />
        <Route exact path="/fornitura" element={<InCostruzione />} />
        <Route exact path="/noleggio" element={<InCostruzione />} />
        <Route exact path="/formazione" element={<InCostruzione />} />
        <Route exact path="/assicurazione" element={<InCostruzione />} />
        <Route exact path="/consulenza" element={<InCostruzione />} />
        <Route exact path="/gestione" element={<InCostruzione />} />
        <Route path="*" element={<Errore404 />} />
    </>
]

const getRoutes = () => {
    return (
        <Switch>
            {routes}
        </Switch>
    )
}

export default getRoutes