import * as React from 'react'
import { useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import MobileStepper from '@mui/material/MobileStepper'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import SwipeableViews from 'react-swipeable-views'
import { autoPlay } from 'react-swipeable-views-utils'
import { useLocation, useNavigate } from "react-router-dom"
import newsPlaceholder from '../utils/placeholder/newsPlaceholder'

const AutoPlaySwipeableViews = autoPlay(SwipeableViews)

const news = newsPlaceholder

function SwipeableView(props) {
    const theme = useTheme()
    const [activeStep, setActiveStep] = React.useState(0)
    const maxSteps = news.length
    const location = useLocation()

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1)
    };

    const handleStepChange = (step) => {
        setActiveStep(step)
    };

    const { sx } = props

    const navigate = useNavigate()

    return (
        <Paper elevation={8} sx={{ ...sx, maxWidth: "65vw", flexGrow: 1 }}>
            <AutoPlaySwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={activeStep}
                onChangeIndex={handleStepChange}
                enableMouseEvents
            >
                {news.map((step, index) => (
                    <div key={step.titolo}>
                        {Math.abs(activeStep - index) <= 2 ? (
                            <Box
                                component="img"
                                sx={{
                                    height: "25vw",
                                    display: 'block',
                                    maxWidth: "65vw",
                                    overflow: 'hidden',
                                    width: '100%',
                                }}
                                src={step.imgPath}
                                alt={step.titolo}
                            />
                        ) : null}
                    </div>
                ))}
            </AutoPlaySwipeableViews>
            <Typography>
                <Box
                    id="titolo"
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: "center",
                        bgcolor: 'tertiary.main',
                        fontWeight: "bold",
                        fontSize: "1.5rem"
                    }}
                >
                    {news[activeStep].titolo}
                </Box>
            </Typography>
            <Box
                id="Anteprima articolo"
                sx={{
                    margin: "1rem",
                    display: 'flex',
                    flexDirection: "column",
                    alignItems: 'start',
                    bgcolor: 'tertiary.main',
                    textAlign: "left"
                }}
            >
                <Typography>{news[activeStep].anteprima}</Typography>
                <Button
                    sx={{
                        backgroundColor: "secondary.main",
                        textTransform: "none",
                        boxShadow: "3px 3px 6px 3px #b6b6b6",
                        alignSelf: "center",
                        "&:hover": {
                            backgroundColor: "secondary.dark",
                        },
                    }}
                    onClick={() => navigate(location.pathname + "news/" + news[activeStep].path)}
                >
                    Scopri di più
                </Button>
            </Box>
            <MobileStepper
                sx={{
                    backgroundColor: "tertiary.main"
                }}
                steps={maxSteps}
                position="static"
                activeStep={activeStep}
                nextButton={
                    <Button
                        size="small"
                        onClick={handleNext}
                        disabled={activeStep === maxSteps - 1}
                        sx={{
                            backgroundColor: "secondary.main",
                            textTransform: "none",
                            boxShadow: "3px 3px 6px 3px #b6b6b6",
                            alignSelf: "center",
                            "&:hover": {
                                backgroundColor: "secondary.dark",
                            },
                        }}
                    >
                        Successivo
                        {theme.direction === 'rtl' ? (
                            <KeyboardArrowLeft />
                        ) : (
                            <KeyboardArrowRight />
                        )}
                    </Button>
                }
                backButton={
                    <Button
                        size="small"
                        onClick={handleBack}
                        disabled={activeStep === 0}
                        sx={{
                            backgroundColor: "secondary.main",
                            textTransform: "none",
                            boxShadow: "3px 3px 6px 3px #b6b6b6",
                            alignSelf: "center",
                            "&:hover": {
                                backgroundColor: "secondary.dark",
                            },
                        }}
                    >
                        {theme.direction === 'rtl' ? (
                            <KeyboardArrowRight />
                        ) : (
                            <KeyboardArrowLeft />
                        )}
                        Precedente
                    </Button>
                }
            />
        </Paper>
    );
}

export default SwipeableView