import React from 'react'
import { Box } from "@mui/material"
import { LocationOn, LocalPhone, Email } from '@mui/icons-material';
import logo from "../res/immagini/logo-nome-laterale.webp"
import Immagine from './Immagine'
import Testo from './Testo'
import TestoEIcona from './TestoEIcona';

function Footer() {
    return (
        <Box sx={{ backgroundColor: "primary.main" }}>
            <Box
                sx={{
                    display: "grid",
                    alignItems: "center",
                    padding: "3rem",
                    gridTemplateColumns: "1fr 1fr"
                }}
            >
                <Immagine immagine={logo} />
                <Box>
                    <Testo
                        testo="Dove siamo"
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            color: "tertiary.main",
                            fontSize: "3rem"
                        }}
                    />
                    <TestoEIcona
                        testo="Via Giovanni Tarchini, 1 22077 Olgiate Comasco (CO)"
                        icona={LocationOn}
                        sx={{ color: "tertiary.main", fontSize: "1.5rem", marginTop: "1rem" }}
                        sxIcona={{ fontSize: "2.5rem" }}
                    />
                    <TestoEIcona
                        testo="+39 031 718 4760"
                        icona={LocalPhone}
                        sx={{ color: "tertiary.main", fontSize: "1.5rem", marginTop: "1rem" }}
                        sxIcona={{ fontSize: "2.5rem" }}
                    />
                    <TestoEIcona
                        testo="info@blacksheepplus.com"
                        icona={Email}
                        sx={{ color: "tertiary.main", fontSize: "1.5rem", marginTop: "1rem" }}
                        sxIcona={{ fontSize: "2.5rem" }}
                    />
                </Box>
            </Box>
            <Testo
                testo="© 2022 BlackSheep+ SRLS | Tutti i diritti riservati."
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "secondary.main"
                }}
            />
        </Box>
    )
}

export default Footer