import { createTheme } from '@mui/material/styles'

export const themeOptions = createTheme({
    palette: {
        type: 'light',
        primary: {
            main: '#000000',
            light: "#b6b6b6",
            dark: "#7c7c7c"
        },
        secondary: {
            main: '#ffed00',
            dark: "#e29e24",
            contrastText: "#000000"
        },
        tertiary: {
            main: '#ffffff',
            dark: '#b6b6b6',
            contrastText: "#000000"
        },
        background: {
            default: '#000000',
        },
        info: {
            main: '#ffed00',
        },
    },
    typography: {
        fontFamily: [
            'Segoe UI',
            // 'cursive',
        ].join(','),
    },
})
