import React from 'react'
import { Box, Typography } from '@mui/material'

function Label(props) {

    const { testo, sx } = props

    return (
        <Typography>
            <Box sx={{
                ...sx,
                borderRadius: "15px",
                boxShadow: "3px 3px 6px 3px #b6b6b6",
                backgroundColor: "tertiary.main",
                opacity: "80%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontWeight: "bold"
            }}>
                {testo}
            </Box>
        </Typography>
    )
}

export default Label