import fornitura from "../res/immagini/serviziPrincipali/main-fornitura.webp"
import noleggio from "../res/immagini/serviziPrincipali/main-noleggio.webp"
import formazione from "../res/immagini/serviziPrincipali/main-formazione.webp"
import assicurazione from "../res/immagini/serviziPrincipali/main-assicurazione.webp"
import consulenza from "../res/immagini/serviziPrincipali/main-consulenza.webp"
import gestione from "../res/immagini/serviziPrincipali/main-gestione.webp"

export const elencoMenu = [
    {
        nome: "Home",
        path: "",
        immagine: null
    },
    {
        nome: "News",
        path: "news",
        immagine: null
    },
    {
        nome: "Fornitura",
        path: "fornitura",
        immagine: fornitura
    },
    {
        nome: "Noleggio",
        path: "noleggio",
        immagine: noleggio
    },
    {
        nome: "Formazione",
        path: "formazione",
        immagine: formazione
    },
    {
        nome: "Assicurazione",
        path: "assicurazione",
        immagine: assicurazione
    },
    {
        nome: "Consulenza",
        path: "consulenza",
        immagine: consulenza
    },
    {
        nome: "Gestione",
        path: "gestione",
        immagine: gestione
    },
]