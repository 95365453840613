import React from 'react'
import { Box, AppBar, Toolbar, Button } from '@mui/material'
import { useNavigate } from "react-router-dom";
import logo from "../res/immagini/logo-nome-laterale.webp"
import richiestaAssistenza from "../res/immagini/richiesta-assistenza.webp"
import { elencoMenu } from '../utils/elencoMenu'

function Header() {

    const navigate = useNavigate()

    const renderMenu = () => {
        var risultato = []
        elencoMenu.forEach(menu => {
            risultato.push(
                <Button
                    variant="text"
                    sx={{ color: "tertiary.main", textTransform: "none", fontSize: "1.2rem", marginX: "1rem" }}
                    onClick={(e) => navigate(menu.path)}
                >
                    {menu.nome}
                </Button>
            )
        })
        return risultato
    }

    return (
        <AppBar position='static'>
            <Toolbar>
                <Box
                    component="img"
                    src={logo}
                    sx={{
                        width: [100, 200, 200],
                        height: [50, 80, 80]
                    }}
                />
                <Box sx={{ display: "flex", marginLeft: "auto", alignItems: "center" }}>
                    <Button
                        sx={{
                            backgroundColor: "tertiary.main",
                            marginRight: "2rem",
                            "&:hover": {
                                backgroundColor: "secondary.main"
                            }
                        }}
                        //disabilita il pulsante di login
                        disabled={true}
                    >
                        Accedi
                    </Button>
                    <Box
                        component="img"
                        src={richiestaAssistenza}
                        sx={{
                            width: [100, 200, 200],
                            height: [35, 70, 70],
                        }}
                    />
                </Box>
            </Toolbar>
            <Toolbar
                sx={{
                    backgroundColor: "primary.light",
                    display: "flex",
                    justifyContent: "center"
                }}
            >
                {renderMenu()}
            </Toolbar>
        </AppBar>
    )
}

export default Header