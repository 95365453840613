const newsPlaceholder = [
    {
        titolo: 'San Francisco – Oakland Bay Bridge, United States',
        imgPath:
            'https://images.unsplash.com/photo-1537944434965-cf4679d1a598?auto=format&fit=crop&w=400&h=250&q=60',
        anteprima: "Blablabla, blablabla, bla bla bla. Blabla",
        path: "usa"
    },
    {
        titolo: 'Bali, Indonesia',
        imgPath:
            'https://images.unsplash.com/photo-1537996194471-e657df975ab4?auto=format&fit=crop&w=400&h=250&q=80',
        anteprima: "Blablabla, blablabla, bla bla bla. Blabla, ma meno bla",
        path: "indonesia"
    },
    {
        titolo: 'Goč, Serbia',
        imgPath:
            'https://images.unsplash.com/photo-1512341689857-198e7e2f3ca8?auto=format&fit=crop&w=400&h=250&q=60',
        anteprima: "Il bla bla più bla di tutti i bla",
        path: "serbia"
    },
    {
        titolo: 'Bird',
        imgPath:
            'https://images.unsplash.com/photo-1538032746644-0212e812a9e7?auto=format&fit=crop&w=400&h=250&q=60',
        anteprima: "Bla bla bird, bird bla blard",
        path: "bird"
    },
]

export default newsPlaceholder